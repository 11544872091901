/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.4/dist/jquery.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.min.js
 * - /npm/@fancyapps/ui@5.0.36/dist/fancybox/fancybox.umd.js
 * - /npm/@fancyapps/ui@5.0.36/dist/carousel/carousel.umd.js
 * - /npm/@fancyapps/ui@5.0.36/dist/carousel/carousel.thumbs.umd.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
